import React from "react";
import { View, Platform } from "react-native";
import { containerColor } from "../BrandData";

// Common styles for the container
const commonStyles = {
  borderRadius: 10,
  backgroundColor: containerColor,
  justifyContent: "center",
  alignItems: "center",
  padding: 20,
};

// Styles for the container based on the platform (Android, iOS, and web)
const containerStyle = Platform.select({
  android: {
    width: "90%",
    elevation: 5,
    ...commonStyles,
  },
  ios: {
    width: "90%",
    shadowColor: "rgba(0, 0, 0, 0.5)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 4,
    ...commonStyles,
  },
  web: {
    width: 350,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
    ...commonStyles,
  },
});

export const Container = ({ children }) => {
  return <View style={containerStyle}>{children}</View>;
};
