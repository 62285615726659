import React from "react";
import { Text, TouchableOpacity, View, StyleSheet } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons"; // Import Icon component
import {
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
} from "../BrandData";

// PrimaryButton component, accepts 'text', 'onPress', and optional 'iconName' props
export const PrimaryButton = ({ text, onPress, iconName }) => {
  return (
    <TouchableOpacity style={styles.primaryButton} onPress={onPress}>
      <View style={styles.buttonContent}>
        <Text style={styles.primaryButtonText}>{text}</Text>
        {iconName && (
          <Icon
            name={iconName}
            size={20}
            color={secondaryTextColor}
            style={styles.icon}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

// Styles for the PrimaryButton component
export const styles = StyleSheet.create({
  primaryButton: {
    backgroundColor: primaryColor,
    borderRadius: 20,
    padding: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  primaryButtonText: {
    color: primaryTextColor,
    fontSize: 15,
  },
  buttonContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginLeft: 5,
  },
});
