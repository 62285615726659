import React from "react";
import { View, StyleSheet } from "react-native";
import {
  footerBackgroundColor,
  footerTextColor,
} from "../BrandData";
import { TextLink } from "./TextLink"; // Import the TextLink component

export function Footer() {
  return (
    <View style={styles.footer}>
      {/* "About" link */}
      <TextLink
        text="About"
        onPress="https://www.example.com/about"
        color={footerTextColor}
      />

      {/* "Help" link */}
      <TextLink
        text="Help"
        onPress="https://www.example.com/help"
        color={footerTextColor}
      />

      {/* "Terms" link */}
      <TextLink
        text="Terms"
        onPress="https://www.example.com/terms"
        color={footerTextColor}
      />

      {/* "Privacy Policy" link */}
      <TextLink
        text="Privacy Policy"
        onPress="https://www.example.com/privacy-policy"
        color={footerTextColor}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    backgroundColor: footerBackgroundColor,
    width: "100%",
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 10,
    marginTop: 25
  },
});
