import React, { useState } from "react";
import { Text, View, StyleSheet, Platform, TextInput } from "react-native";
import { appName, containerTextColor } from "../BrandData";
import { PrimaryButton } from "./PrimaryButton";
import { TextLink } from "./TextLink";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Container } from "./Container";
import FirebaseApp from "../firebaseConfig";

const auth = getAuth();

export function ForgotPasswordContainer({ navigation }) {
  const [email, setEmail] = useState(""); // State for email input
  const [recoverySent, setRecoverySent] = useState(false); // State to track account recovery sent or not
  const [errorMessage, setErrorMessage] = useState("");

  const handlesendRecovery = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setRecoverySent(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error sending reset email:", error);
        setErrorMessage(errorMessage);
        //Display error message for 5 seconds
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  return (
    <Container>
      <View style={styles.loginForm}>
        {/* Forgot Password text */}
        <Text style={styles.headerText}>Forgot Your {appName} Password?</Text>

        {/* Email Input when recovery sent*/}
        {recoverySent ? null : (
          <TextInput
            style={styles.input}
            onChangeText={(text) => setEmail(text)}
            placeholder="Email"
            value={email}
          />
        )}

        {/* Send reset link or back to home if account created */}
        {recoverySent ? (
          <PrimaryButton
            text="Go to Login"
            onPress={() => navigation.navigate("Login")}
          />
        ) : (
          <PrimaryButton text="Send Reset Link" onPress={handlesendRecovery} />
        )}

        {/* Remember your password? */}
        {recoverySent ? null : (
          <TextLink
            text="Remember your password? Login here"
            onPress={() => navigation.navigate("Login")}
          />
        )}
        {errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null}
      </View>
    </Container>
  );
}

export const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 100,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  loginForm: {
    gap: 20,
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
  },
  headerText: {
    textAlign: "center",
    fontSize: 20,
    color: containerTextColor,
  },
  orText: {
    textAlign: "center",
  },
  line: {
    backgroundColor: "#F4F4F4",
    height: 2,
    flex: 1,
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});
