import React, { useState } from "react";
import { Text, View, StyleSheet, TextInput } from "react-native";
import { appName, containerTextColor } from "../BrandData";
import { PrimaryButton } from "../Components/PrimaryButton";
import { SecondaryButton } from "../Components/SecondaryButton";
import { TextLink } from "./TextLink";
import { Container } from "./Container";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import FirebaseApp from "../firebaseConfig";

export function LoginContainer({ navigation }) {
  const [email, setEmail] = useState(""); // State for email input
  const [password, setPassword] = useState(""); // State for password input
  const [errorMessage, setErrorMessage] = useState(""); // State to track error messages
  const auth = getAuth();

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        // Navigate to Home screen
        navigation.navigate("TabNavigationHub");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Login Error:", error);
        //Display error message for 5 seconds
        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  return (
    <Container>
      <View style={styles.loginForm}>
        {/* Log in to text */}
        <Text style={styles.headerText}>Login to {appName}</Text>

        {/* Email */}
        <TextInput
          style={styles.input}
          placeholder="Email"
          value={email}
          onChangeText={setEmail} // Update email state
        />
        {/* Password */}
        <TextInput
          style={styles.input}
          secureTextEntry={true}
          placeholder="Password"
          value={password}
          onChangeText={setPassword} // Update password state
        />

        {/* Login button */}
        <PrimaryButton text="Login" onPress={handleSignIn} iconName="login" />
        {/*Show Error Messages */}
        {errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null}

        {/* Forgot Password */}
        <TextLink
          text="Forgot password?"
          onPress={() => navigation.navigate("ForgotPassword")}
        />

        {/* OR text Section */}
        <View style={{ flexDirection: "row", alignItems: "center", gap: 10 }}>
          <View style={styles.line} />
          <Text style={styles.orText}>or</Text>
          <View style={styles.line} />
        </View>

        {/* Create Account button */}
        <SecondaryButton
          text="Create new account"
          onPress={() => navigation.navigate("CreateAccount")}
        />
      </View>
    </Container>
  );
}

export const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 100,
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  loginForm: {
    gap: 10,
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerText: {
    textAlign: "center",
    fontSize: 20,
    color: containerTextColor,
  },
  orText: {
    textAlign: "center",
  },
  line: {
    backgroundColor: "#F4F4F4",
    height: 2,
    flex: 1,
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});
