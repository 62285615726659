import React, { useEffect, useRef } from "react";
import { StatusBar } from "expo-status-bar";
import { Platform, View, StyleSheet } from "react-native";
import LottieView from "lottie-react-native"; // For iOS and Android

const LottieAnimation = ({ source }) => {
  const animationRef = useRef(null);

  useEffect(() => {
    if (Platform.OS === "web") {
      // Ensure lottie-web is required only on web platforms
      const lottieWeb = require("lottie-web");

      // Initialize Lottie animation for web
      const anim = lottieWeb.loadAnimation({
        container: animationRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: source,
      });

      return () => anim.destroy();
    }
  }, []);

  return Platform.OS === "web" ? (
    <div ref={animationRef} style={styles.webContainer} />
  ) : (
    <View style={styles.nativeContainer}>
      <StatusBar style="dark" />
      <LottieView source={source} autoPlay loop />
    </View>
  );
};

const styles = StyleSheet.create({
  webContainer: {
    width: "100%",
    height: "100%",
  },
  nativeContainer: {
    width: "100%",
    height: "100%",
  },
});

export default LottieAnimation;
