import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/MaterialIcons";
import { primaryColor } from "../BrandData";
import { getAuth, signOut } from "firebase/auth";

const SettingsButton = ({ text, iconName, onPress }) => (
  <View style={styles.border}>
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <View style={styles.iconContainer}>
        <Icon name={iconName} size={20} color={primaryColor} />
      </View>
      <Text style={styles.buttonText}>{text}</Text>
    </TouchableOpacity>
  </View>
);

export function SettingsScreen({ navigation }) {
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Navigate back to the 'Login' screen
        navigation.navigate("Login");
      })
      .catch((error) => {
        //catch errors
        console.error("Sign out error:", error);
      });
  };

  return (
    <View style={styles.container}>
      <SettingsButton text="Help" iconName="help" />
      <SettingsButton text="Subscription" iconName="payments" />
      <SettingsButton text="Delete Account" iconName="delete" />
      <SettingsButton
        text="Log Out"
        iconName="exit-to-app"
        onPress={handleLogout}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F4F4F4",
  },
  button: {
    flexDirection: "row",
    width: "100%",
    paddingVertical: 20,
    paddingHorizontal: 10,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 5,
  },
  buttonText: {
    fontSize: 18,
    color: "#000000",
    fontWeight: "500",
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: "#CECECE",
  },
});
