import React, { useEffect }  from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { SettingsScreen } from "./Screens/SettingScreen";
import { HomeScreen } from "./Screens/HomeScreen";
import { FeatureScreen } from "./Screens/FeatureScreen";
import { StatusBar } from "expo-status-bar";

import {
  primaryColor,
  footerBackgroundColor,
  footerTextColor,
  headerTextColor,
} from "./BrandData";
import Icon from "react-native-vector-icons/MaterialIcons";

const Tab = createBottomTabNavigator();

function MainTabs() {
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarStyle: styles.tabBar,
        tabBarActiveTintColor: primaryColor,
        tabBarInactiveTintColor: footerTextColor,
        headerStyle: {
          backgroundColor: footerBackgroundColor,
        },
        headerTitleStyle: {
          color: headerTextColor,
        },
      }}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name="home" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Feature"
        component={FeatureScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name="explore" size={size} color={color} />
          ),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          tabBarIcon: ({ color, size }) => (
            <Icon name="settings" size={size} color={color} />
          ),
        }}
      />
    </Tab.Navigator>
  );
}

export function TabNavigationHub({ navigation }) {
  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar style="dark" />
      <View style={styles.container}>
        <MainTabs />
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: footerBackgroundColor,
  },
  container: {
    flex: 1,
    backgroundColor: "#fff",
    justifyContent: "flex-end",
  },
  tabBar: {
    backgroundColor: footerBackgroundColor,
  },
});
