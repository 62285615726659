import React, { useState, useEffect } from "react";
import { ScrollView, StyleSheet, Text } from "react-native"; // Import ScrollView
import { getAuth } from "firebase/auth";

export function HomeScreen() {
  const [user, setUser] = useState(null);
  const auth = getAuth();
  useEffect(() => {
    setUser(auth.currentUser);
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text style={styles.text}>Start building your app here!</Text>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F4F4",
    paddingTop: 20,
  },
});
