import React, { useRef, useState, useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LottieAnimation from "../Components/LottieAnimation";
import Loading from "../assets/loading.json";

export function LoadingScreen({ navigation }) {
  const animation = useRef(null);
  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    // Set a timeout for 2 seconds
    const timer = setTimeout(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {

          navigation.navigate("TabNavigationHub");
        } else {

          navigation.navigate("Login");
        }
      });

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, 2000); // 2000 milliseconds = 2 second

    // Clear the timeout if the component unmounts before the timeout finishes
    return () => clearTimeout(timer);
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.animation}>
        <LottieAnimation source={Loading} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F4F4F4",
    paddingTop: 20,
  },
  animation: {
    height: "40%",
    width: "40%",
  },
});
