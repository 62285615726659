import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Platform } from 'react-native';

const firebaseConfig = {
    apiKey: "AIzaSyAWctcRhBQga8x4U2ppmmJelsiB8IRYmhE",
    authDomain: "buffalab-login-flow.firebaseapp.com",
    projectId: "buffalab-login-flow",
    storageBucket: "buffalab-login-flow.appspot.com",
    messagingSenderId: "629738350079",
    appId: "1:629738350079:web:109f4ed1d402f9817e33ca",
    measurementId: "G-PZ161SGM4K"
};

const app = initializeApp(firebaseConfig);

let auth;

// Check platform and initialize Firebase Auth accordingly
if (Platform.OS !== 'web') {
    // React Native specific imports and initialization
    const { initializeAuth, getReactNativePersistence } = require('firebase/auth');
    const AsyncStorage = require('@react-native-async-storage/async-storage').default;

    auth = initializeAuth(app, {
        persistence: getReactNativePersistence(AsyncStorage),
    });
} else {
    // Web specific initialization
    auth = getAuth(app);
}

export default auth;
