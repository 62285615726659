import React, { useState } from 'react';
import { Text, TouchableOpacity, Linking, Platform } from 'react-native';
import { highlightTextColor } from '../BrandData';

export function TextLink({ text, onPress, color }) {
  const [isHighlighted, setIsHighlighted] = useState(false);

  // Handle the press event based on the type of 'onPress' prop
  const handleLinkPress = () => {
    if (typeof onPress === 'string') {
      // If 'onPress' is a string, treat it as a URL and open it
      Linking.openURL(onPress);
    } else if (typeof onPress === 'function') {
      // If 'onPress' is a function, execute it
      onPress();
    }
  };

  // Handle the touch start event to highlight the link
  const handleTouchStart = () => {
    setIsHighlighted(true);
  };

  // Handle the touch end event to remove the highlight
  const handleTouchEnd = () => {
    setIsHighlighted(false);
  };

  const linkTextStyles = {
    color: color || highlightTextColor,
    textAlign: 'center',
    textDecorationLine: isHighlighted && Platform.OS === 'web' ? 'underline' : 'none',
  };

  return (
    // TouchableOpacity for making the text clickable and handling touch and mouse events
    <TouchableOpacity
      onPress={handleLinkPress}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleTouchStart}
      onMouseLeave={handleTouchEnd}
    >
      <Text style={linkTextStyles}>{text}</Text>
    </TouchableOpacity>
  );
}
