import {
  View,
  Image,
  Text,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import { StatusBar } from "expo-status-bar";
import { LoginContainer } from "../Components/LoginContainer";
import { tagline, appLogo, backgroundColor, taglineColor } from "../BrandData";
import { Footer } from "../Components/Footer";


export function LoginScreen({ navigation }) {
  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === "ios" ? "padding" : "height"} // Adjust the behavior based on the platform
      enabled
    >
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <StatusBar style="light" />
        <View style={styles.header}>
          <Image source={appLogo} style={styles.logo} />
          <Text style={styles.tagline}>{tagline}</Text>
          <LoginContainer navigation={navigation} />
        </View>
        <Footer />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

export const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: backgroundColor,
    paddingTop: 20,
  },
  header: {
    flex: 1, 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    gap: '20px', 
    width: '100%'
  },
  logo: {
    width: 200,
    height: 200
  },
  tagline: {
    textAlign: "center",
    fontSize: 25,
    color: taglineColor,
  },
});
