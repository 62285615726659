import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { LoginScreen } from "./Screens/LoginScreen";
import { CreateAccountScreen } from "./Screens/CreateAccountScreen";
import { ForgotPasswordScreen } from "./Screens/ForgotPasswordScreen";
import { HomeScreen } from "./Screens/HomeScreen";
import { TabNavigationHub } from "./TabNavigationHub";
import { LoadingScreen } from "./Screens/LoadingScreen";
import { appName } from "./BrandData";

const Stack = createNativeStackNavigator();

export default function App() {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Loading">
        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateAccount"
          component={CreateAccountScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Home"
          component={HomeScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="TabNavigationHub"
          component={TabNavigationHub}
          options={{ headerShown: false, title: appName }}
        />
        <Stack.Screen
          name="Loading"
          component={LoadingScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
