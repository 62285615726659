import React, { useState } from "react";
import { Text, View, StyleSheet, Platform, TextInput } from "react-native";
import { appName, containerTextColor } from "../BrandData";
import { PrimaryButton } from "./PrimaryButton";
import { TextLink } from "./TextLink";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Container } from "./Container";
import FirebaseApp from "../firebaseConfig";

export function CreateAccountContainer({ navigation }) {
  const [email, setEmail] = useState(""); // State for email input
  const [password, setPassword] = useState(""); // State for password input
  const [accountCreated, setAccountCreated] = useState(false); // State to track account creation status
  const [errorMessage, setErrorMessage] = useState(""); // State to track error messages
  const auth = getAuth();
  const handleCreateAccount = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        // Update the state to indicate that the account has been created
        setAccountCreated(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error creating account:", error);
        //Display error message for 5 seconds
        setErrorMessage(errorMessage);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  return (
    <Container>
      <View style={styles.form}>
        {/* Create an Account text */}
        <Text style={styles.headerText}>
          {accountCreated
            ? `Account Created Successfully`
            : `Create a ${appName} Account`}
        </Text>

        {/* Email input and password input, hidden when account created */}
        {accountCreated ? null : (
          <View>
            <TextInput
              style={styles.input}
              placeholder="Email"
              value={email}
              onChangeText={(text) => setEmail(text)} // Update email state
            />
            <TextInput
              style={styles.input}
              placeholder="Password"
              secureTextEntry={true}
              value={password}
              onChangeText={(text) => setPassword(text)} // Update password state
            />
          </View>
        )}

        {/* Create Account button or back to home if account created */}
        {accountCreated ? (
          <PrimaryButton
            text="Go to Login"
            onPress={() => navigation.navigate("Login")}
          />
        ) : (
          <PrimaryButton
            text="Create Account"
            onPress={handleCreateAccount}
            disabled={accountCreated}
          />
        )}
        {accountCreated ? null : (
          <TextLink
            text="Already have an account? Login here"
            onPress={() => navigation.navigate("Login")}
          />
        )}
        {errorMessage ? (
          <Text style={styles.errorMessage}>{errorMessage}</Text>
        ) : null}
      </View>
    </Container>
  );
}

export const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderRadius: 100,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  form: {
    gap: 10,
    width: "100%",
    paddingTop: 5,
    paddingBottom: 5,
  },
  headerText: {
    textAlign: "center",
    fontSize: 20,
    color: containerTextColor,
  },
  orText: {
    textAlign: "center",
  },
  line: {
    backgroundColor: "#F4F4F4",
    height: 2,
    flex: 1,
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});
