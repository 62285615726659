// Application Basics
export const appName = 'Zero To App';
export const tagline = 'Build, launch and iterate!';
export const appLogo = require('./assets/logo.png');

// Color Scheme
export const primaryColor = '#00A9B6';
export const secondaryColor = '#000000';
export const primaryTextColor = '#FFFFFF';
export const secondaryTextColor = '#FFFFFF';
export const backgroundColor = '#00A9B6';
export const taglineColor = '#FFFFFF';
export const highlightTextColor = '#5987FF';

// Container Styles
export const containerColor = '#FFFFFF';
export const containerTextColor = '#000000';

// Header and Footer Styles
export const headerTextColor = '#000000';
export const footerBackgroundColor = '#FFFFFF';
export const footerTextColor = '#8F8F8F';
